'use client';

import Script from 'next/script';
import { FC, useCallback, useEffect } from 'react';

import { useConsent } from '@lichtblick/consent/hooks/useConsent';

export const HubSpotFormScript: FC = () => {
  const linkedinConsent = useConsent('LinkedIn Ads');
  const facebookConsent = useConsent('Facebook');
  const googleAdsConsent = useConsent('Google Ads');
  const googleCustomerMatchConsent = useConsent('Google Customer Match');

  const hubspotEventHandler = useCallback(
    (event: any) => {
      if (event?.data?.type === 'hsFormCallback' && event?.data?.eventName === 'onFormSubmit') {
        const _hsq = (window as any)._hsq || [];

        _hsq.push([
          'trackCustomBehavioralEvent',
          {
            name: 'pe143379264_usercentrics_consent_given',
            properties: {
              consent_status_meta: facebookConsent,
              consent_status_linkedin: linkedinConsent,
              consent_status_google_ads: googleAdsConsent,
              consent_status_google_customer_match: googleCustomerMatchConsent,
            },
          },
        ]);

        _hsq.push(['trackPageView']);
      }
    },
    [linkedinConsent, facebookConsent, googleAdsConsent, googleCustomerMatchConsent],
  );

  useEffect(() => {
    window.addEventListener('message', hubspotEventHandler);

    return () => window.removeEventListener('message', hubspotEventHandler);
  }, [hubspotEventHandler]);

  return (
    <Script
      id="hubspot-contact-form-script"
      onReady={() => {
        (window as any).hbspt.forms.create({
          region: 'eu1',
          portalId: '143379264',
          formId: 'ebf80417-cba2-44f7-a01e-2c17c6bad7da',
          target: '#hubspot-contact-form',
        });
      }}
      src="//js-eu1.hsforms.net/forms/embed/v2.js"
      type="text/javascript"
    />
  );
};
